<template>
    <div>
        <loading-card v-if="utilisateur == null || saving || deleting">
            <template v-if="saving">Enregistrement en cours ...</template>
            <template v-else-if="deleting">Suppression en cours ...</template>
            <template v-else>Chargement du utilisateur ...</template>
        </loading-card>

        <b-form v-else>
            <validation-observer ref="simpleRules">
                <b-card title="Informations" class="card-info">
                    <b-row>
                        <b-col lg="6" md="12">
                            <b-form-group label="Identifiant" label-for="username">
                                <validation-provider #default="{ errors }" name="Identifiant" rules="required">
                                    <b-form-input 
                                        v-model="username"
                                        id="username"
                                        placeholder="Identifiant"
                                        :state="errors.length > 0 ? false : null" 
                                    />
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" md="12">
                            <b-form-group label="Mot de passe" label-for="password">
                                <validation-provider #default="{ errors }" name="Mot de passe" :rules="creating ? 'required' : ''">
                                    <b-form-input 
                                        v-model="password"
                                        id="password"
                                        placeholder="Mot de passe"
                                        :state="errors.length > 0 ? false : null" 
                                    />
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col lg="6" md="12">
                            <b-form-group label="Prénom" label-for="firstname">
                                <validation-provider #default="{ errors }" name="Prénom" rules="required">
                                    <b-form-input 
                                        v-model="firstname"
                                        id="firstname"
                                        placeholder="Prénom"
                                        :state="errors.length > 0 ? false : null" 
                                    />
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" md="12">
                            <b-form-group label="Nom" label-for="lastname">
                                <validation-provider #default="{ errors }" name="Nom" rules="required">
                                    <b-form-input 
                                        v-model="lastname"
                                        id="lastname"
                                        placeholder="Nom"
                                        :state="errors.length > 0 ? false : null" 
                                    />
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col lg="12" md="12">
                            <b-form-group label="Email" label-for="email">
                                <validation-provider #default="{ errors }" name="Email" rules="email">
                                    <b-form-input 
                                        v-model="email"
                                        id="email"
                                        placeholder="Email"
                                        :state="errors.length > 0 ? false : null" 
                                    />
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col lg="12" md="12">
                            <b-form-checkbox
                                class="custom-control-primary pb-1"
                                v-model="active"
                                name="active"
                            >
                                Activé
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-card>

                <b-card title="Droits" class="card-other" >
                    <b-row>
                        <b-col>
                            <b-form-group
                                v-slot="{ ariaDescribedby }"
                                >
                                <b-form-checkbox
                                    class="custom-control-primary pb-1"
                                    v-for="option in rolesOptions"
                                    v-model="roles"
                                    :key="option.value"
                                    :value="option.value"
                                    :aria-describedby="ariaDescribedby"
                                    name="roles"
                                >
                                    <b>{{ option.text }}</b>
                                    <template v-if="option.hasOwnProperty('desc')">
                                        ({{option.desc}})
                                    </template>
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card>

                <b-card title="Comptabilité" class="card-other" >
                    <b-row>
                        <b-col lg="6" md="12">
                            <b-form-group label="Code Comptabilité" label-for="codeComptable">
                                    <b-form-input 
                                        v-model="codeComptable"
                                        id="codeComptable"
                                        placeholder="Code Comptabilité"
                                    />
                            </b-form-group>
                        </b-col>

                        <b-col>
                            <b-form-group label="Code Nature Sage" label-for="codeNature">
                                <b-form-input 
                                        v-model="codeNature"
                                        id="codeNature"
                                        placeholder="Code Nature Sage"
                                    />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card>

                <b-row>
                    <b-col sm="12" lg="6" md="8">
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" to="/utilisateurs/list" class="mr-1">
                            <feather-icon icon="ListIcon" class="mr-50"/>
                            <span class="align-middle">Revenir à la liste</span>
                        </b-button>

                        <b-button variant="gradient-success" type="submit" @click.prevent="validationForm">
                            <feather-icon icon="CheckIcon" class="mr-50"/>
                            <span class="align-middle">{{creating ? "Ajouter" : "Enregistrer"}}</span>
                        </b-button>
                    </b-col>

                    <b-col sm="12" lg="6" md="4" :class="deleteClass" v-if="!creating">
                        <b-button variant="gradient-danger" @click="confirmDeleteUtilisateur(utilisateur.id, utilisateur.username, goBack)">
                            <feather-icon icon="TrashIcon" class="mr-50"/>
                            <span class="align-middle">Supprimer</span>
                        </b-button>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-form>
    </div>
</template>

<script>
    import {filterArray} from "@/services/utils"
    import LoadingCard from "@/views/GDLoadingCard"
    import utilisateurApi from "@/api/utilisateurApi"
    import miscApi from "@/api/miscApi"
    import {computedFactory, storeComputedFactory} from "@/services/computedFactory"
    import { computed, ref } from '@vue/composition-api'
    import { confirmDeleteUtilisateur, deleteUtilisateur } from "@/views/setups/utilisateur"
    import { arraysAreEqual } from "@/services/arrayHelper"
    import vSelect from 'vue-select'

    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email as veeEmail } from '@validations'

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    
    export default {
        components: {
            LoadingCard,
            ValidationProvider,
            ValidationObserver,
            ToastificationContent,
            vSelect
        },

        data() {
            return {
                saving: false,
                deleting: false,
                roles: [],
                password: "",

                rolesOptions: [
                    { text: 'Chargé d\'Affaire', value: 'ROLE_CHARGE_AFFAIRE' },
                    { text: 'Comptabilité', value: 'ROLE_COMPTABILITE' },
                    { text: 'Direction', value: 'ROLE_DIRECTION' },
                    { text: 'Administratif', value: 'ROLE_ADMINISTRATIF', desc: "Possibilité d'ajouter, de modifier et de supprimer des utilisateurs, clients, agences ..." },
                    { text: 'Valider pour la direction', value: 'ROLE_CAN_VALIDATE_FOR_DIRECTION', desc: "Permet de valider un DEC à la place de la direction" },
                ]
            }
        },

        beforeMount() {
            if(this.$route.params.hasOwnProperty("utilisateurId"))
                this.fetchUtilisateur();
        },

        setup(props, {root}) {
            const currentBreakPoint = computed(() => root.$store.getters['app/currentBreakPoint'])
            const expectedFields = ["username", "firstname", "lastname", "email", "active", "codeNature", "codeComptable"];
            let utilisateurBase = null;
            let creatingBase = !root.$route.params.hasOwnProperty("utilisateurId");
            if(creatingBase) {
                utilisateurBase = expectedFields.reduce((accumulator, field) => ({...accumulator, [field]: ""}), {});
                utilisateurBase = {...utilisateurBase, roles: ["ROLE_USER"], password: "", active: 1}
            }

            const utilisateur = ref(utilisateurBase);
            const creating = ref(creatingBase)
            return {
                utilisateur,
                creating,
                expectedFields,
                ...storeComputedFactory(["comptabiliteTypes"], root.$store),
                ...computedFactory(expectedFields, "utilisateur"),
                currentBreakPoint,
                required, veeEmail,
                confirmDeleteUtilisateur,
                deleteUtilisateur
            }
        },

        computed: {
            deleteClass() {
                return (this.currentBreakPoint == "sm" || this.currentBreakPoint == "xs") ? "mt-1" : "text-right";
            }
        },

        methods: {
            filterType(options, search) {
                return filterArray(options, ["libelle"], search)
            },

            goBack() {
                // ! ATTENTION => fonctionne dans le cas retour à la liste. si on utilise le composant Utilisateur à un autre endroit
                // ! il faudra surement revoir la logique. a garder en tête.
                this.$router.go(-1)
            },

            async fetchUtilisateur() {
                this.utilisateur = await utilisateurApi.getUtilisateur(this.$route.params.utilisateurId);
                this.roles = this.utilisateur.roles;
            },

            async validationForm() {
                let res = await this.$refs.simpleRules.validate()
                if(res)
                    this.save();
            },
            
            async save() {
                this.saving = true;
                let data = this.expectedFields.filter(field => this[field] != this.utilisateur[field]).reduce((accumulator, currentValue) => ({...accumulator, [currentValue]: this[currentValue]}), {});
                if(data.hasOwnProperty("typeComptabilite"))
                    data["typeComptabilite"] = data["typeComptabilite"]["@id"];
               if(!arraysAreEqual(this.roles, this.utilisateur.roles))
                    data.roles = this.roles;

                if(this.password != "")
                    data.plainPassword = this.password;

                console.log("data", data)
                let callApi = this.creating ? () => utilisateurApi.addUtilisateur(data) : () => utilisateurApi.patchUtilisateur(this.utilisateur.id, data)
                try {
                    if(Object.entries(data).length != 0)
                        await callApi();
                    this.showToast("success", this.creating ? "Utilisateur créé" : "Utilisateur mis à jour", "CheckIcon")
                    this.$router.go(-1);
                } catch(e) {
                    console.log("e", e)
                    this.showToast("danger", "Une erreur s'est produite", "AlertCircleIcon")
                }
                finally {
                    this.saving = false;
                }
            },

            // TODO déplacer dans service, en faire une méthode générique
            showToast(variant, text, icon) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        icon,
                        text,
                        variant
                    },
                }, {position: "bottom-right", timeout: 2500})
            },
        }

    }
</script>


<style>
    .danger,
    .danger .vs__selected-options,
    .danger .vs__dropdown-toggle {
        color: red;
        border-color: red;
    }
</style>